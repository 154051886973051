import { getApiGatewayUrl } from '@trello/api-gateway';
import { Analytics } from '@trello/atlassian-analytics';
import { trelloServerMicrosUrl } from '@trello/config';
import { sendNetworkErrorEvent } from '@trello/error-reporting';
import { fetch, trelloFetch } from '@trello/fetch';
import { parseNetworkError } from '@trello/graphql-error-handling';
import { getNetworkClient } from '@trello/network-client';
import { getCsrfRequestPayload } from '@trello/session-cookie/csrf';

import type {
  EnterpriseClaimableOrganizationsArgs,
  EnterpriseExportArgs,
  EnterpriseManagedMembersWithTokensArgs,
  EnterpriseMembershipsArgs,
  EnterpriseOrganizationsArgs,
  EnterpriseTransferrableDataArgs,
  MutationAssignMemberEnterpriseAdminArgs,
  MutationClaimOrganizationArgs,
  MutationCreateSeatAutomationExportArgs,
  MutationCreateSelfServeExpansionArgs,
  MutationDeactivateEnterpriseMemberArgs,
  MutationDeleteManagedMemberTokensArgs,
  MutationDismissCompletedWorkspaceBatchesArgs,
  MutationGrantEnterpriseLicenseArgs,
  MutationLinkEnterpriseWithAtlassianOrganizationArgs,
  MutationReactivateMemberArgs,
  MutationRemoveEnterpriseMemberArgs,
  MutationRunSeatAutomationArgs,
  MutationStartEnterpriseExportArgs,
  MutationUpdateDefaultWorkspaceArgs,
  MutationUpdateEnterpriseApiTokenCreationPermissionArgs,
  MutationUpdateEnterprisePrefsSeatAutomationArgs,
  MutationUpdateEnterprisePrefsSeatAutomationBlockedMembersArgs,
  QueryFetchSeatAutomationExportArgs,
  QueryFetchSeatAutomationPreviewArgs,
  QueryGetWorkspacesForMemberArgs,
  QuerySeatAutomationBlocklistMembersArgs,
  QuerySeatAutomationHistoryArgs,
  QuerySeatAutomationNextRunDateArgs,
  QuerySelfServeExpansionEstimateArgs,
} from '../generated';
import { isQueryInfo } from '../isQueryInfo';
import { prepareDataForApolloCache } from '../prepareDataForApolloCache';
import {
  getChildFieldNames,
  getChildNodes,
} from '../restResourceResolver/queryParsing';
import type { TrelloRestResolver } from '../types';

/**
 * Enterprise.organizations is queried as a custom resolver (as opposed to
 * utilizing restResourceResolver)for two reasons
 *
 * 1. At the time of this resolver's writing, the Enterprise API did not support the
 * "sortBy", "sortOrder", "count", or "startIndex" params for the Organizations nested
 * resource.
 *
 * 2. The API returns a list of Organizations, while the GQL type includes both
 * the list of Organizations, as well as the total count of Organizations (extracted
 * via an HTTP header)
 */
export const enterpriseOrganizationsResolver: TrelloRestResolver<
  EnterpriseOrganizationsArgs
> = async (
  enterprise: {
    id: string;
  },
  args,
  context,
  info,
) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const { count, startIndex, query, activeSince, inactiveSince } = args ?? {};

  const searchParams = new URLSearchParams({
    ...(Number.isInteger(count) && { count: String(count) }),
    ...(Number.isInteger(startIndex) && { startIndex: String(startIndex) }),
    ...(query && { filter: `displayName co "${query}"` }),
    ...(activeSince && { activeSince }),
    ...(inactiveSince && { inactiveSince }),
  });

  const children = getChildNodes(rootNode);
  const organizationsSelection = children.find(
    (child) => child.name.value === 'organizations',
  );
  if (organizationsSelection) {
    searchParams.set(
      'fields',
      getChildFieldNames(organizationsSelection, ['memberships']).join(','),
    );
  }

  const networkClient = getNetworkClient();
  const url = networkClient.getUrl(
    `/1/enterprises/${
      enterprise.id
    }/claimedWorkspaces?${searchParams.toString()}`,
  );

  const response = await trelloFetch(url, undefined, {
    clientVersion: context.clientAwareness.version,
    networkRequestEventAttributes: {
      source: 'graphql',
      resolver: 'Enterprise.organizations',
      operationName: context.operationName,
    },
    deduplicate: context.deduplicate,
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  const organizations = await response.json();

  const apiQueryMetaHeader = response.headers.get('x-trello-api-query-meta');
  const apiQueryMeta = apiQueryMetaHeader
    ? JSON.parse(apiQueryMetaHeader)
    : null;
  const totalOrganizations = apiQueryMeta?.['totalResults'] ?? 0;
  const model = {
    organizations,
    totalOrganizations,
  };
  return model
    ? prepareDataForApolloCache(model, rootNode, 'Enterprise')
    : model;
};

export const enterpriseClaimableOrganizationsResolver: TrelloRestResolver<
  EnterpriseClaimableOrganizationsArgs
> = async (
  enterprise: {
    id: string;
  },
  args,
  context,
  info,
) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  let model = null;
  const { limit, cursor, name, activeSince, inactiveSince } = args;

  const nameQuery = name ? `&name=${name}` : '';

  const activeSinceQuery =
    activeSince && !inactiveSince ? `&activeSince=${activeSince}` : '';

  const inactiveSinceQuery =
    !activeSince && inactiveSince ? `&inactiveSince=${inactiveSince}` : '';

  const queryParams = `limit=${limit || 20}&cursor=${encodeURIComponent(
    cursor || '',
  )}${nameQuery}${activeSinceQuery}${inactiveSinceQuery}`;

  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/enterprises/${enterprise.id}/claimableOrganizations?${queryParams}`,
  );

  try {
    const response = await trelloFetch(apiUrl, undefined, {
      clientVersion: context.clientAwareness.version,
      networkRequestEventAttributes: {
        source: 'graphql',
        resolver: 'Enterprise.claimableOrganizations',
        operationName: context.operationName,
      },
    });

    if (response.ok) {
      const {
        organizations,
        claimableCount,
        cursor: nextCursor,
      } = await response.json();

      model = {
        organizations,
        count: claimableCount,
        cursor: nextCursor,
      };
    } else {
      throw new Error(
        `An error occurred while resolving a GraphQL query. (status: ${response.status}, statusText: ${response.statusText})`,
      );
    }

    return model
      ? prepareDataForApolloCache(model, rootNode, 'Enterprise')
      : model;
  } catch (err) {
    console.error(err);
    return model;
  }
};

export const transferrableDataForOrganizationResolver: TrelloRestResolver<
  EnterpriseTransferrableDataArgs
> = async (
  enterprise: {
    id: string;
  },
  args,
  context,
  info,
) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/enterprises/${enterprise.id}/transferrable/bulk?idOrganizations=${args.idOrganizations}`,
  );

  const response = await trelloFetch(apiUrl, undefined, {
    clientVersion: context.clientAwareness.version,
    networkRequestEventAttributes: {
      source: 'graphql',
      resolver: 'Enterprise.transferrableData',
      operationName: context.operationName,
    },
  });

  if (!response.ok) {
    const error = await response.text();
    console.error(error);
    throw new Error(error);
  }

  const model = await response.json();

  // The API should probably not be sending back status 200 for this, but alas
  if ('message' in model) {
    console.error(model);
    throw new Error(model.message);
  }

  return model
    ? prepareDataForApolloCache(model.organizations, rootNode, 'Enterprise')
    : model;
};

export const startEnterpriseExport: TrelloRestResolver<
  MutationStartEnterpriseExportArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const response = await fetch(`/1/enterprises/${args.id}/exports`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: JSON.stringify({
      ...getCsrfRequestPayload(),
      enterpriseAssociationType: args.enterpriseAssociationType,
    }),
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.error);
  }

  const body = await response.json();

  return prepareDataForApolloCache(body, rootNode);
};

export const getEnterpriseExportResolver: TrelloRestResolver<
  EnterpriseExportArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const response = await trelloFetch(
    `/1/enterprise/${args.idEnterprise}/exports/${args.idExport}`,
    undefined,
    {
      clientVersion: context.clientAwareness.version,
      networkRequestEventAttributes: {
        source: 'graphql',
        resolver: 'Enterprise.export',
        operationName: context.operationName,
      },
    },
  );
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.error);
  }
  const body = await response.json();
  return prepareDataForApolloCache(body, rootNode, 'Enterprise');
};

export const deactivateEnterpriseMember: TrelloRestResolver<
  MutationDeactivateEnterpriseMemberArgs
> = async (obj, { idEnterprise, idMember }, context, info) => {
  const params = new URLSearchParams(
    getCsrfRequestPayload({ fallbackValue: '' }),
  );
  params.set('value', 'true');

  const networkClient = getNetworkClient();
  const response = await fetch(
    networkClient.getUrl(
      `/1/enterprises/${idEnterprise}/members/${idMember}/deactivated`,
    ),
    {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'X-Trello-Client-Version': context.clientAwareness.version,
      },
      body: params,
    },
  );

  if (!response.ok) {
    const error = await response.text();
    throw new Error(error);
  }
  return response;
};

export const assignMemberEnterpriseAdmin: TrelloRestResolver<
  MutationAssignMemberEnterpriseAdminArgs
> = async (obj, { idEnterprise, idMember, isAdmin }, context, info) => {
  const params = new URLSearchParams(
    getCsrfRequestPayload({ fallbackValue: '' }),
  );
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/enterprises/${idEnterprise}/admins/${idMember}`,
  );
  const response = await fetch(apiUrl, {
    method: isAdmin ? 'PUT' : 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: params,
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }
  return response;
};

export const grantEnterpriseLicense: TrelloRestResolver<
  MutationGrantEnterpriseLicenseArgs
> = async (obj, { idEnterprise, idMember }, context, info) => {
  const params = new URLSearchParams(
    getCsrfRequestPayload({ fallbackValue: '' }),
  );
  params.set('value', 'true');

  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/enterprises/${idEnterprise}/members/${idMember}/licensed`,
  );
  const response = await fetch(apiUrl, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: params,
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }
  return response;
};

export const reactivateMember: TrelloRestResolver<
  MutationReactivateMemberArgs
> = async (obj, { idEnterprise, idMember }, context, info) => {
  const params = new URLSearchParams(
    getCsrfRequestPayload({ fallbackValue: '' }),
  );
  params.set('value', 'false');

  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/enterprises/${idEnterprise}/members/${idMember}/deactivated`,
  );
  const response = await fetch(apiUrl, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: params,
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }
  return response;
};

export const removeEnterpriseMember: TrelloRestResolver<
  MutationRemoveEnterpriseMemberArgs
> = async (obj, { idEnterprise, idMember }, context, info) => {
  const params = new URLSearchParams(
    getCsrfRequestPayload({ fallbackValue: '' }),
  );
  params.set('value', 'true');

  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/enterprises/${idEnterprise}/members/${idMember}`,
  );
  const response = await fetch(apiUrl, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: params,
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }
  return response;
};

export const claimOrganization: TrelloRestResolver<
  MutationClaimOrganizationArgs
> = async (obj, { idEnterprise, idOrganizations, traceId }, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();

  const apiUrl = networkClient.getUrl(
    `/1/enterprises/${idEnterprise}/organizations/bulk`,
  );
  const response = await fetch(apiUrl, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
      ...Analytics.getTaskRequestHeaders(traceId),
    },
    body: JSON.stringify({
      idOrganizations,
      ...getCsrfRequestPayload(),
    }),
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  const model = await response.json();

  return prepareDataForApolloCache(model, rootNode);
};

export const declineOrganizations: TrelloRestResolver<
  MutationClaimOrganizationArgs
> = async (obj, { idEnterprise, idOrganizations, traceId }, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();

  const response = await fetch(
    networkClient.getUrl(
      `/1/enterprises/${idEnterprise}/enterpriseJoinRequest/bulk`,
    ),
    {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-Trello-Client-Version': context.clientAwareness.version,
        ...Analytics.getTaskRequestHeaders(traceId),
      },
      body: JSON.stringify({
        idOrganizations,
        ...getCsrfRequestPayload(),
      }),
    },
  );

  if (!response.ok) {
    const error = await response.text();
    throw new Error(error);
  }

  const model = await response.json();

  return prepareDataForApolloCache(model, rootNode);
};

export const linkEnterpriseWithAtlassianOrganization: TrelloRestResolver<
  MutationLinkEnterpriseWithAtlassianOrganizationArgs
> = async (
  obj,
  { idEnterprise, atlOrgId, enterpriseARI, linkToAtlassianOrgV2 },
  context,
  info,
) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  let apiUrl;

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const performFetch = async (apiUrl: string, reqBody = {}) => {
    const response = await fetch(apiUrl, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-Trello-Client-Version': context.clientAwareness.version,
      },
      body: JSON.stringify(reqBody),
    });
    if (!response.ok) {
      sendNetworkErrorEvent({
        url: apiUrl,
        response: await response.clone().text(),
        status: response.status,
        operationName: context.operationName,
      });
    }
    return response;
  };

  const networkClient = getNetworkClient();
  if (linkToAtlassianOrgV2) {
    const callbackUrl = `${trelloServerMicrosUrl}/1/atl/backfillProvisionerCallback/${idEnterprise}`;
    const reqBody = {
      callbackUrl,
      audience: 'trello-server',
    };
    apiUrl = getApiGatewayUrl(
      `/admin/private/api/admin/v1/orgs/${atlOrgId}/workspaces/${encodeURIComponent(
        enterpriseARI,
      )}/_link`,
    );
    const response = await performFetch(apiUrl, reqBody);
    if (!response.ok) {
      throw await parseNetworkError(response);
    }
  }

  apiUrl = networkClient.getUrl(
    `/1/enterprises/${idEnterprise}/linkWithAtlassianOrganization`,
  );
  const response = await performFetch(apiUrl, {
    atlOrgId,
    ...getCsrfRequestPayload(),
  });
  if (!response.ok) {
    throw await parseNetworkError(response);
  }

  return prepareDataForApolloCache({ success: true }, rootNode);
};

export const dismissCompletedWorkspaceBatches: TrelloRestResolver<
  MutationDismissCompletedWorkspaceBatchesArgs
> = async (obj, { idEnterprise, idBatches }, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/enterprises/${idEnterprise}/organizations/add-to-enterprise-batches/completed`,
  );
  const response = await fetch(apiUrl, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: JSON.stringify({
      ...getCsrfRequestPayload(),
      idsBatch: idBatches,
    }),
  });
  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }
  const model = await response.json();
  return prepareDataForApolloCache(model, rootNode);
};

export const managedMembersWithTokensResolver: TrelloRestResolver<
  EnterpriseManagedMembersWithTokensArgs
> = async (
  enterprise: {
    id: string;
  },
  args,
  context,
  info,
) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  let model = null;

  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/enterprises/${enterprise.id}/members/tokens?filter=${args.filter}`,
  );

  try {
    const response = await trelloFetch(apiUrl, undefined, {
      clientVersion: context.clientAwareness.version,
      networkRequestEventAttributes: {
        source: 'graphql',
        resolver: 'Enterprise.managedMembersWithTokens',
        operationName: context.operationName,
      },
    });

    if (!response.ok) {
      const error = await response.text();
      throw new Error(error);
    }

    model = await response.json();

    return model
      ? prepareDataForApolloCache(model, rootNode, 'Enterprise')
      : model;
  } catch (err) {
    console.error(err);
    return model;
  }
};

export const deleteManagedMemberTokens: TrelloRestResolver<
  MutationDeleteManagedMemberTokensArgs
> = async (obj, { idEnterprise, idMember, filter }, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const response = await fetch(
    networkClient.getUrl(
      `/1/enterprises/${idEnterprise}/members/tokens/${idMember}`,
    ),
    {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-Trello-Client-Version': context.clientAwareness.version,
      },
      body: JSON.stringify({
        filter,
        ...getCsrfRequestPayload(),
      }),
    },
  );

  if (!response.ok) {
    const error = await response.text();
    console.error(error);
    throw new Error(error);
  }

  return prepareDataForApolloCache({ success: true }, rootNode);
};

export const deleteAllManagedMemberTokens: TrelloRestResolver<
  MutationDeleteManagedMemberTokensArgs
> = async (obj, { idEnterprise, filter }, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const response = await fetch(
    networkClient.getUrl(`/1/enterprises/${idEnterprise}/members/tokens`),
    {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-Trello-Client-Version': context.clientAwareness.version,
      },
      body: JSON.stringify({
        filter,
        ...getCsrfRequestPayload(),
      }),
    },
  );

  if (!response.ok) {
    const error = await response.text();
    console.error(error);
    throw new Error(error);
  }

  return prepareDataForApolloCache({ success: true }, rootNode);
};

export const updateEnterpriseApiTokenCreationPermission: TrelloRestResolver<
  MutationUpdateEnterpriseApiTokenCreationPermissionArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const response = await fetch(
    networkClient.getUrl(`/1/enterprise/${args.idEnterprise}`),
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-Trello-Client-Version': context.clientAwareness.version,
      },
      body: JSON.stringify({
        'prefs/canIssueManagedConsentTokens': args.isAllowed,
        ...getCsrfRequestPayload(),
      }),
    },
  );

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.error);
  }

  const body = await response.json();

  return prepareDataForApolloCache(body, rootNode);
};

export const auditlogResolver: TrelloRestResolver<null> = async (
  enterprise: { id: string },
  args,
  context,
  info,
) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const response = await trelloFetch(
    networkClient.getUrl(`/1/enterprise/${enterprise.id}/auditlog`),
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Trello-Client-Version': context.clientAwareness.version,
      },
    },
  );

  if (!response.ok) {
    const error = await response.text();
    throw new Error(error);
  }

  const body = await response.json();

  return prepareDataForApolloCache(body, rootNode, 'Enterprise');
};

export const selfServeExpansionEstimateResolver: TrelloRestResolver<
  QuerySelfServeExpansionEstimateArgs
> = async (obj, args, context, info) => {
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/enterprises/${args.idEnterprise}/seat-expansion-estimated-price/${args.seats}`,
  );
  const response = await trelloFetch(apiUrl, {
    credentials: 'include',
    headers: {
      'X-Trello-Client-Version': context.clientAwareness.version,
      Authorization: `Bearer ${document.cookie}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    const error = await response.text();
    throw new Error(error);
  }

  const result = await response.json();

  return result;
};

export const createSelfServeExpansion: TrelloRestResolver<
  MutationCreateSelfServeExpansionArgs
> = async (obj, args, context) => {
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/enterprises/${args.idEnterprise}/seat-expansion`,
  );
  const response = await fetch(apiUrl, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'X-Trello-Client-Version': context.clientAwareness.version,
      Authorization: `Bearer ${document.cookie}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      seats: args.seats,
      ...getCsrfRequestPayload(),
    }),
  });

  return { ok: response.ok };
};

export const defaultOrganizationResolver: TrelloRestResolver<null> = async (
  enterprise: {
    id: string;
  },
  args,
  context,
) => {
  const networkClient = getNetworkClient();

  const apiUrl = networkClient.getUrl(
    `/1/enterprises/${enterprise.id}/organizations/default`,
  );

  const response = await trelloFetch(apiUrl, undefined, {
    clientVersion: context.clientAwareness.version,
    networkRequestEventAttributes: {
      source: 'graphql',
      resolver: 'Enterprise.defaultOrganization',
      operationName: context.operationName,
    },
  });

  if (response.status === 404) {
    // The workspace hasn't been set yet - that's OK
    return null;
  }

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  return response.json();
};

export const updateDefaultWorkspace: TrelloRestResolver<
  MutationUpdateDefaultWorkspaceArgs
> = async (obj, args, context) => {
  const networkClient = getNetworkClient();

  const apiUrl = networkClient.getUrl(
    `/1/enterprises/${args.idEnterprise}/organizations/default`,
  );
  const response = await fetch(apiUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
      ...Analytics.getTaskRequestHeaders(args.traceId),
    },
    body: JSON.stringify({
      idOrganization: args.idOrganization,
      ...getCsrfRequestPayload(),
    }),
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  return response.json();
};

export const membershipsResolver: TrelloRestResolver<
  EnterpriseMembershipsArgs
> = async (
  enterprise: {
    id: string;
  },
  { after, filter },
  context,
  info,
) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];

  // The query endpoint does not allow values to be null, undefined or empty.
  // They must be removed from the request body object.
  const filterWithoutNulls = Object.fromEntries(
    Object.entries(filter).filter(
      ([_, v]) => v !== null && v !== undefined && v !== '',
    ),
  );

  const searchParams = new URLSearchParams(
    filterWithoutNulls as Record<string, string>,
  );
  if (after) {
    searchParams.append('cursor', after);
  }
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/enterprises/${enterprise.id}/members/query?${searchParams.toString()}`,
  );

  const response = await trelloFetch(apiUrl, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  const body = await response.json();
  return prepareDataForApolloCache(body, rootNode, 'Enterprise');
};

export const updateEnterprisePrefsSeatAutomation: TrelloRestResolver<
  MutationUpdateEnterprisePrefsSeatAutomationArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(`/1/enterprises/${args.idEnterprise}`);
  const response = await fetch(apiUrl, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
      ...Analytics.getTaskRequestHeaders(args.traceId),
    },
    body: JSON.stringify({
      [`prefs/seatAutomationSetting/${args.pref}`]: args.value,
      ...getCsrfRequestPayload(),
    }),
  });
  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }
  const enterprise = await response.json();
  return prepareDataForApolloCache(enterprise, rootNode);
};

export const updateEnterprisePrefsSeatAutomationBlockedMembers: TrelloRestResolver<
  MutationUpdateEnterprisePrefsSeatAutomationBlockedMembersArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(`/1/enterprises/${args.idEnterprise}`);
  const response = await fetch(apiUrl, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: JSON.stringify({
      [`prefs/seatAutomationSetting/memberBlocklist`]: args.value,
      ...getCsrfRequestPayload(),
    }),
  });
  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }
  const enterprise = await response.json();
  return prepareDataForApolloCache(enterprise, rootNode);
};

export const getWorkspacesForMemberResolver: TrelloRestResolver<
  QueryGetWorkspacesForMemberArgs
> = async (obj, { idMember, idEnterprise }, context) => {
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/enterprises/${idEnterprise}/organizations?fields=name,displayName,logoHash,memberships,url&sortOrder=ascending&filter=(members.value eq "${idMember}")`,
  );
  const response = await trelloFetch(apiUrl, {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
  });
  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }
  const workspaces = await response.json();
  return workspaces;
};

export const seatAutomationHistoryResolver: TrelloRestResolver<
  QuerySeatAutomationHistoryArgs
> = async (obj, { idEnterprise }, context) => {
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/enterprises/${idEnterprise}/seatautomations`,
  );

  const response = await trelloFetch(apiUrl, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
  });

  const body = await response.json();
  return body;
};

export const seatAutomationPreviewResolver: TrelloRestResolver<
  QueryFetchSeatAutomationPreviewArgs
> = async (obj, { idEnterprise, activeDays, inactiveDays }, context, info) => {
  const networkClient = getNetworkClient();
  const params = new URLSearchParams();
  params.set('activeDays', activeDays.toString());
  params.set('inactiveDays', inactiveDays.toString());

  const apiUrl = networkClient.getUrl(
    `/1/enterprises/${idEnterprise}/seatautomation/preview?${params.toString()}`,
  );

  const response = await trelloFetch(apiUrl, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
  });
  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }
  const body = await response.json();
  return body;
};

export const seatAutomationBlocklistMembersResolver: TrelloRestResolver<
  QuerySeatAutomationBlocklistMembersArgs
> = async (obj, { idEnterprise }, context, info) => {
  const networkClient = getNetworkClient();

  const apiUrl = networkClient.getUrl(
    `/1/enterprises/${idEnterprise}/seatautomation/memberBlocklistInfo`,
  );

  const response = await trelloFetch(apiUrl, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
  });
  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }
  const body = await response.json();
  return { blocklistMembers: body.members };
};

export const runSeatAutomation: TrelloRestResolver<
  MutationRunSeatAutomationArgs
> = async (obj, { idEnterprise }, context, info) => {
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/enterprises/${idEnterprise}/seatautomation`,
  );
  const response = await trelloFetch(apiUrl, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: JSON.stringify({
      ...getCsrfRequestPayload(),
    }),
  });
  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  const body = await response.json();
  return body;
};

export const createSeatAutomationExport: TrelloRestResolver<
  MutationCreateSeatAutomationExportArgs
> = async (obj, { idEnterprise, idSeatAutomation }, context) => {
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(`/1/enterprises/${idEnterprise}/exports`);

  const response = await fetch(apiUrl, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
      Accept: 'application/json',
    },
    body: JSON.stringify({
      ...getCsrfRequestPayload(),
      seatAutomationId: idSeatAutomation,
    }),
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }
  const body = await response.json();
  return body;
};

export const fetchSeatAutomationExportResolver: TrelloRestResolver<
  QueryFetchSeatAutomationExportArgs
> = async (obj, { idEnterprise, idExport }, context) => {
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/enterprise/${idEnterprise}/exports/${idExport}`,
  );

  const response = await trelloFetch(apiUrl, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }
  const body = await response.json();
  return body;
};

export const seatAutomationNextRunDateResolver: TrelloRestResolver<
  QuerySeatAutomationNextRunDateArgs
> = async (_, { idEnterprise }, context) => {
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/enterprise/${idEnterprise}/seatautomation/next-run-date`,
  );

  const response = await trelloFetch(apiUrl, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  const body = await response.json();
  return body;
};
